/*eslint-disable*/

import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import OrderFormTemplate from "./OrderFormTemplate";
import data from "./data.json";
import { useGlobalState } from 'components/globalVariable';
import { json } from "react-router-dom";
import getAvailableMargin from "components/Margin/container/cypherResult";
import { setAvailableMargin } from "components/Margin/container/MarginTemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import { saveFormOpen, saveTradingSymbol } from "components/PositionsGrid/container/PositionActionSlice";
import { setTradingSymbol, setShowOrderForm } from "components/OptionChain/OptionChainSlice";
import config  from "../../../config";
import fetchBetaValues from "components/Beta/getPortfoliobeta";
let OrderType = [
  "MARKET",
  "LIMIT",
  "STANDALONE_SL_LIMIT",
  "STANDALONE_SL_MARKET",
  "LIMIT_ORDER_WITH_SL_LIMIT",
  "MARKET_ORDER_WITH_SL_LIMIT",
  "LIMIT_ORDER_WITH_SL_MARKET",
  "MARKET_ORDER_WITH_SL_MARKET",
];
const Prices    = ["triggerPrice", "limitPrice", "stopLossLimitPrice"];
function OrderForm({setFormOpen, trade, updateData, setUpdateData,targetNode, hideForm}) {
  const companiesList               = data.companies
  const [clientId]                  = useGlobalState("clientId")
  const [accountIds]                = useGlobalState("accountIds")
  const [orderValue, setOrderValue] = useState([]);
  const [zen,setZen]                = useState(targetNode)
  const dispatch                    = useDispatch()
  const autoSquareTradingSymbol = useSelector(state => state.positionActions.tradingSymbol);
  const orderStrategyId = useSelector(state => state.filter.orderStrategyId)
  const exchange = useSelector(state => state.filter.exchange)
  const [formData, setFormData]     = useState({
    clientId:           clientId,
    accountId:          accountIds?accountIds[0]:[],
    strategyId:         updateData !== undefined ? updateData.strategyId: Number(orderStrategyId),
    broker:             updateData !== undefined ? updateData.broker : "ZEN_BROKER",
    exchange:           updateData !== undefined ? updateData.exchange : "ZEN_EXCHANGE",
    transactionType:    trade,
    quantity:           updateData !== undefined ? updateData.quantity : null,
    product:            updateData !== undefined ? updateData.product : "CNC",
    validity:           updateData !== undefined ? updateData.validity : "DAY",
    minutes:            updateData !== undefined ? updateData.minutes : null,
    stopLossLimitPrice: updateData !== undefined ? updateData.stopLossLimitPrice : null,
    triggerPrice:       updateData !== undefined ? updateData.triggerPrice : null,
    limitPrice:         updateData !== undefined ? updateData.limitPrice : null,
    tradingSymbol:      autoSquareTradingSymbol?autoSquareTradingSymbol:!zen ? updateData !== undefined ? updateData.tradingSymbol : null : zen,
    orderType:          updateData !== undefined ? updateData.orderType : null,
    methodType:         "POST"
  });
  const { register, handleSubmit, formState, reset, control, watch, setError, setValue, getValues } = useForm({
    defaultValues: formData,
  });
  
  const broker = watch("broker");
  const handleChange = (selectedOption) => {
    switch (selectedOption) {
      case "MARKET":
        setOrderValue(["triggerPrice", "limitPrice", "stopLossLimitPrice"]);
        break;
      case "LIMIT":
        setOrderValue(["triggerPrice", "stopLossLimitPrice"]);
        break;
      case "STANDALONE_SL_LIMIT":
        setOrderValue(["limitPrice"]);
        break;
      case "STANDALONE_SL_MARKET":
        setOrderValue(["limitPrice","stopLossLimitPrice"]);
        break;
      case "LIMIT_ORDER_WITH_SL_LIMIT":
        setOrderValue([]);
        break;
      case "MARKET_ORDER_WITH_SL_LIMIT":
        setOrderValue(["limitPrice"]);
        break;
      case "LIMIT_ORDER_WITH_SL_MARKET":
        setOrderValue(["stopLossLimitPrice"]);
        break;
      case "MARKET_ORDER_WITH_SL_MARKET":
        setOrderValue(["limitPrice", "stopLossLimitPrice"]);
        break;
      default:
        setOrderValue([]);
        break;
    }
  };
  const { errors } = formState;
  function onSubmit(data) {


    if (data.validity !== "Minute") {
      data.minutes = null;
    }
    if (data.broker==="ZEN_BROKER"){
      data.exchange="ZEN_EXCHANGE"
    }
    else{
      data.exchange=exchange
    }
    
    if(data.minutes!==null){
      data.minutes=Number(data.minutes)
    }
    for (const i of orderValue) {
      data[i]=null;
    }
    data.strategyId         = parseInt(data.strategyId)
    data.quantity           = parseInt(data.quantity);
    data.limitPrice         = parseFloat(data.limitPrice);
    data.triggerPrice       = parseFloat(data.triggerPrice);
    data.stopLossLimitPrice = parseFloat(data.stopLossLimitPrice);
    fetch(config.REACT_APP_DEV_URL+'order/placeOrder',
        {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body:JSON.stringify(data),
        })
        .then(response=>{
            if (!response.ok) {
              throw new Error('Not able to place the order');
            }
            return response.text();
          }
        )
        .then(json=>alert(json))
        .then(()=>{
          dispatch(saveFormOpen(false))
          dispatch(saveTradingSymbol(""));
          dispatch(setTradingSymbol(""))

        })
        .catch((err) => {
            alert("An error occurred: " + err.message);
        });
    getAvailableMargin(clientId, dispatch, setAvailableMargin)
    fetchBetaValues(clientId,accountIds,dispatch)
    dispatch(setShowOrderForm(false))
    reset();
    setZen("")
    updateData !== undefined ? setUpdateData(null) : setFormOpen((formOpen) => !formOpen)
  }
  return (
    <>
    <OrderFormTemplate
      formData      = {updateData}
      setUpdateData = {setUpdateData}
      companiesList = {companiesList}
      setFormOpen   = {setFormOpen}
      trade         = {trade}
      zen           = {zen}
      handleSubmit = {handleSubmit}
      setError     = {setError}
      onSubmit     = {onSubmit}
      register     = {register}
      errors       = {errors}
      setValue     = {setValue}
      getValues    = {getValues}
      watch        = {watch}
      OrderType    = {OrderType}
      control      = {control}
      orderValue   = {orderValue}
      handleChange = {handleChange}
      Prices       = {Prices}
      broker       = {broker}
      setFormData  = {setFormData}
      hideForm={hideForm}
    />
    </>
  );
}
export default OrderForm;
/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formOpen : true
};

const orderFormSlice = createSlice({
  name: 'orderForm',
  initialState,
  reducers: {
    
    setFormOpen(state, action){
        state.formOpen = action.payload
    }
  }
});

export const { setFormOpen} = orderFormSlice.actions;
export default orderFormSlice.reducer;

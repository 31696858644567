/* eslint-disable */ 
import OrderForm from 'components/OrderForm/Field/OrderForm';
import { DraggableComponent } from 'components/OrderForm/Field/draggable';
import { useGlobalState } from 'components/globalVariable';
import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveTradingSymbol,saveFormOpen,saveOpen,saveIsConfirmed, saveOrderDetails } from '../container/PositionActionSlice';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import config  from "../../../config";
import getAvailableMargin from 'components/Margin/container/cypherResult';
import { setAvailableMargin } from 'components/Margin/container/MarginTemplateSlice';
import fetchBetaValues from 'components/Beta/getPortfoliobeta';


export default function PositionActions() {
    const [formOpen, setFormOpen] = useState(false);
    const formOpen2 = useSelector(state => state.positionActions.formOpen);
    const orderType = useSelector(state => state.positionActions.type);
    const tradingSymbol = useSelector(state => state.positionActions.tradingSymbol);
    const open = useSelector(state => state.positionActions.open);
    const orderData = useSelector(state => state.positionActions.orderData);
    const isConfirmed = useSelector(state => state.positionActions.isConfirmed);
    const [clientId] = useGlobalState("clientId")
    const [accountIds]                = useGlobalState("accountIds")

    const dispatch = useDispatch();

    const orderDetails = useSelector(state => state.positionActions.orderDetails);


    const hideForm = (formData, setUpdateData) => {
        if (formData !== null && formData !== undefined) {
        setUpdateData(null);
        } else {
        setFormOpen((formOpen) => !formOpen);
        }
        dispatch(saveFormOpen(false));
        dispatch(saveTradingSymbol(""));
        dispatch(saveOrderDetails(""));
    }

    useEffect(()=>{
        if(isConfirmed){
            fetch(config.REACT_APP_DEV_URL+'order/placeOrder',
                {
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body:JSON.stringify(orderData),
                })
                .then(response=>{
                    if (!response.ok) {
                      throw new Error('Not able to place the order');
                    }
                    return response.text();
                  }
                )
                .then(json=>alert(json))
                .then(()=>{
                  dispatch(saveFormOpen(false))
                  dispatch(saveTradingSymbol(""));
                  getAvailableMargin(clientId, dispatch, setAvailableMargin)
                  fetchBetaValues(clientId,accountIds,dispatch)

                })
                .catch((err) => {
                    alert("An error occurred: " + err.message);
                });
        }
        dispatch(saveIsConfirmed(false))
    },[isConfirmed])

  return (
    <div>
      {formOpen2? (
        <div style={{
          position: 'fixed',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          zIndex: 1000
   
         }}>
        <DraggableComponent>
        <OrderForm
          setFormOpen = {setFormOpen}
          trade       = {orderType}
          hideForm={hideForm}
          asqOffDetails={orderDetails}
        />
        </DraggableComponent>
        </div>
      ):null}
       <React.Fragment>
        <Dialog
          open={open}
          onClose={()=>saveOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"This action cannot be undone"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure to auto square off <b>{orderDetails?.symbol}</b> ?
            </DialogContentText>
            <br/>
            <DialogContentText id="alert-dialog-details">
                <p className='text-md'>Order details</p>
                <p className='text-md'>Transaction type : <b className={`${orderDetails?.type==="BUY"?'text-green-500':'text-red-500'}`}>{orderDetails?.type}</b></p>
                <p className='text-md'>Quantity : <b>{orderDetails?.quantity}</b></p>
                <p className='text-md'>Order type : <b>{orderDetails?.orderType}</b></p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{
                dispatch(saveIsConfirmed(false));
                
                dispatch(saveOpen(false))

            }}>Cancel</Button>
            <Button onClick={()=>{
                dispatch(saveIsConfirmed(true));
                
                dispatch(saveOpen(false));
            }} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
    </React.Fragment>
    </div>
  )
}

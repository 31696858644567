/*eslint-disable*/
import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
import { AgGridReact } from 'ag-grid-react'; 
import { Button, Spinner, Alert } from "reactstrap";
import { CiEdit } from "react-icons/ci";
import { TbPencilCancel } from "react-icons/tb";
//import OrderForm from '../OrderForm/Field/OrderForm';
import { useGlobalState } from 'components/globalVariable';
import { ShimmerTable } from 'react-shimmer-effects';
import OrderForm from 'components/OrderForm/Field/OrderForm';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TableRefresher from 'components/TableRefreshComponet/TableRefresher';
import OrderStatusCellRender from './OrderStatusCellRender';
import config  from "../../config";
import { useDispatch, useSelector } from 'react-redux';
import { setEditFormOpen, setEditableOrderData } from 'components/EditOrderForm/EditOrderFormSlice';
import { useRealtimeZenSecurities } from 'components/getSecurities';
import { setLots } from 'components/EditOrderForm/EditOrderFormSlice';
import { setLotSize } from 'components/EditOrderForm/EditOrderFormSlice';

function OrderTable () {

    const [clientId]                    = useGlobalState("clientId")
    const [accountIds]                  = useGlobalState("accountIds")
    const [clientName]                  = useGlobalState('clientName');
    const dispatch = useDispatch();
    const gridRef                       = useRef(); 
    const [rowData, setRowData]         = useState(); 
    const [count, setCount]             = useState(1);
    const [isError, setIsError]         = useState(false);
    const [errorMsg, setErrorMsg]       = useState(null);
    const [refreshTime, setRefreshTime] = useState(new Date().toLocaleTimeString([], { hour12: true }).replace('pm', 'PM'));
    const [updateData,setUpdateData]    = useState(null)   
    const { dates,broker, security, exchange, portfolio, entity, strategy } = useSelector(state => state.filter);
    const strategyList = useSelector(state => state.filter.strategyList);
    const zenSecuritiesData = useRealtimeZenSecurities();
    const editableOrderData = useSelector(state => state.editOrderForm.editableOrderData);
    const lots = useSelector(state => state.editOrderForm.lots);
    const [formOpen, setFormOpen] = useState(false);

    const dateConversion = (val) => {
      const dateObj = new Date(val);
      const year = dateObj.getFullYear();
      const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); 
      const day = ('0' + dateObj.getDate()).slice(-2);
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
  }
    const orderExecutionTimeGetter = (params) => {
      const rowData = params.api.rowModel.rowsToDisplay.map(row => row.data);
      if (rowData) {
          rowData.sort((a, b) => new Date(b.brokerTimestamp).getTime() - new Date(a.brokerTimestamp).getTime());
      }
      return params.value.replace('T', ' ').slice(0, 19);
  };
  
  
  document.addEventListener('keydown', function(event){
    if(event.key === 'Escape')
    {      
      dispatch(setFormOpen(false));
    }
  });

  const editCellRenderer = (params) => { 
    

    const handleIconClick=()=>{
      // const rowData = params.data;
      // console.log("Row data :",rowData)
      // fetch('http://192.168.20.12:9190/order/getOrders', {
      //   method: 'POST',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify
      //   (
      //     {
      //       positionCompKeyFilter: {
      //       clientId: rowData.positionCompKey.clientId ,
      //       strategyIds: [rowData.positionCompKey.strategyId] ,
      //       brokers: [rowData.positionCompKey.broker] ,
      //       accountIds: [rowData.positionCompKey.accountId],
      //       tradingSymbols: [rowData.tradingSymbol] 
      //       }}
      //     ),
      // })
      // .then(response => {
      //   if (!response.ok) {
      //     throw new Error(message);
      //   }
      //   return response.json();
      // })
      // .then(data => {
      //   console.log("Before filtered data : ", data);
      //   const filteredData = data?.filter(item => item.zenOrderId === rowData.zenOrderId);
      //   console.log(filteredData?.length , rowData?.zenOrderId  );
      //   const latestData = filteredData?.length === 1? filteredData[0] : (filteredData?.reduce((latest, current) => {
      //     const currentDateTime = new Date(current.orderCreationTime);
      //     const latestDateTime = new Date(latest.orderCreationTime);
      //     return currentDateTime > latestDateTime ? current : latest; 
      // }))   
      // console.log(latestData) 
      //   dispatch(setEditableOrderData(latestData));
      //   let lotsData;
      //   zenSecuritiesData.then((data)=>{
          
      //     for (const exchange in data){
      //       if(exchange === latestData.exchange){
      //         console.log(exchange, latestData.exchange)
      //         if(latestData?.tradingSymbol?.includes("CE")){
      //           lotsData = data[exchange]["CE"].filter((item)=>item.tradingSymbol===latestData?.tradingSymbol)[0]
      //         }
      //         else if(latestData?.tradingSymbol?.includes("PE")){
      //           lotsData = data[exchange]["PE"].filter((item)=>item.tradingSymbol===latestData?.tradingSymbol)[0]
      //         }
      //         else if(latestData?.tradingSymbol?.includes("FUT")){
      //           lotsData = data[exchange]["FUT"].filter((item)=>item.tradingSymbol===latestData?.tradingSymbol)[0]
                
      //         }else{
      //           lotsData = data[exchange]["EQ"].filter((item)=>item.tradingSymbol===latestData?.tradingSymbol)[0]
      //         }
      //       }
      //     }
      //   }).then(()=>{
          
      //     console.log("Quantity : ", latestData?.quantity, lotsData?.lotSize, Number(latestData?.quatity) /Number(lotsData?.lotSize)) 
      //     dispatch(setLotSize(lotsData?.lotSize))
      //     dispatch(setLots(latestData?.quantity /(lotsData?.lotSize)))
      //   })
      //   dispatch(setEditFormOpen(true));
      //   // setUpdateData(data.filter(item => item.zenOrderId === rowData.zenOrderId)[0]);
      //   // dispatch(setFormOpen(false));
      // })
      // .catch(error => {
      //   console.error('There was a problem with the fetch operation:', error);
      // });

      // fetch('http://192.168.20.12:9190/order/getOrders', {
      //   method: 'POST',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify
      //   (
      //     {
      //       positionCompKeyFilter: {
      //       clientId: rowData.positionCompKey.clientId ,
      //       strategyIds: [rowData.positionCompKey.strategyId] ,
      //       brokers: [rowData.positionCompKey.broker] ,
      //       accountIds: [rowData.positionCompKey.accountId],
      //       tradingSymbols: [rowData.tradingSymbol] 
      //       }}
      //     ),
      // })
      // .then(response => {
      //   if (!response.ok) {
      //     throw new Error(message);
      //   }
      //   return response.json();
      // })
      // .then(data => {
      //   console.log("Before filtered data : ", data);
      //   const filteredData = data?.filter(item => item.zenOrderId === rowData.zenOrderId);
      //   console.log(filteredData?.length , rowData?.zenOrderId  );
      //   const latestData = filteredData?.length === 1? filteredData[0] : (filteredData?.reduce((latest, current) => {
      //     const currentDateTime = new Date(current.orderCreationTime);
      //     const latestDateTime = new Date(latest.orderCreationTime);
      //     return currentDateTime > latestDateTime ? current : latest; 
      // }))   
      // console.log(latestData) 
      //   // dispatch(setEditableOrderData(latestData));
      //   setUpdateData(latestData);
      //   setFormOpen(true);
      //   // dispatch(setFormOpen(false));
      // })
      // .catch(error => {
      //   console.error('There was a problem with the fetch operation:', error);
      // });
      

      //I need to make reques to getorders and find order details for zenorderid
    }
    
    if (params.data.status=="PENDING"){
      return <CiEdit 
      style={{cursor:"pointer", fontSize: "22px",}}
      onClick={handleIconClick} />;
    }
    else{
      return <TbPencilCancel 
      style={{cursor:"not-allowed", fontSize: "22px",color:' rgb(192, 190, 190)'}}
      />
    }
    
  };

  



  const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Edit",
        field: "edit",
        headerClass: 'align-left',
        cellClass: 'align-left',
        cellStyle: { textAlign: "left" },
        cellRenderer: editCellRenderer,
        width: 50,
        flex:0,
        editable: false
        
      }
      ,    
      {
        headerName: "Trading Symbol", field: 'tradingSymbol', 
        headerClass: 'align-left',
        cellClass: 'align-left',
        cellStyle:{textAlign:"left"},
        flex:0,
        minWidth:130  
      },
      {
        headerName: "Broker", field: 'positionCompKey.broker', 
        headerClass: 'align-left',
        cellStyle: { textAlign: "left" },
        width:120,
        flex:0     
      },
      {
        headerName: "Strategy Name", field: "strategyLists", 
        headerClass: 'align-left',
        cellStyle: { textAlign: "left" },
        width:180,
        sort: null,
        valueGetter: (params) => {
            const strategyId = params?.data?.positionCompKey?.strategyId; 
            return strategyId && strategyList ? strategyList[strategyId] : ''
        }    
    },
      
      {
        headerName: "Status", field: 'status', 
        headerClass: 'align-left',
        cellClass: 'align-left',
        cellStyle:{textAlign:"center"},
        cellRenderer: OrderStatusCellRender,
        
        width:100
      },
      // {
      //   headerName: "Message", field: 'message', 
      //   headerClass: 'align-left',
      //   cellClass: 'align-left',
      //   cellStyle:{textAlign:"left"},
      //   width:100,
      //   flex:1
             
      // },
      {
        headerName: "OrderResponse Time", field: 'brokerTimestamp', minWidth:"190",
        headerClass: 'align-left',
        cellClass: 'align-left',
        valueFormatter:orderExecutionTimeGetter,
        sort: 'desc'
      },   
      {
        headerName: "ZenOrder Id", field: 'zenOrderId', 
        headerClass: "ag-right-aligned-header",
        cellClass: 'align-left',
        flex:0,
        minWidth:100,
        cellStyle:{display: "flex",justifyContent: "flex-end"}    
      },
  ]);

  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitCellContents",
    };
  }, []);

  
  const defaultColDef = useMemo(() => ({
    sortable: true,
    editable: true,
    filter: true,
  }), []);


  var cellClickedListener = ( ) => {
    setCount(count + 1);
  };

  const [isFetching,setIsFetching] = useState(true);
  useEffect(() => {
    setIsFetching(true)
    const body = {
      positionCompKeyFilter: {
          clientId: clientId,
          tradingSymbols: security === "" ? [] : security,
          // Conditionally include strategyIds only if strategy is not empty
          ...(strategy ? { strategyIds: [Number(strategy)] } : { strategyIds : Object.keys(strategyList).map(item => Number(item))}),
          accountIds: accountIds,
          brokers : broker === "ALL" ? ["ZEN_BROKER", "ZERODHA"] : [broker]
      }
    };
    const fetchData = async () => {
      try {
        const response = await fetch(config.REACT_APP_DEV_URL+'order/getZenOrderState', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        const rowData = await response.json();
        
        setRowData(Array.from(rowData));
        setRefreshTime(new Date().toLocaleTimeString([], { hour12: true }));
        setTimeout(()=>setIsFetching(false),750);
        setIsError(false);
        /*
        setTimeout(() => { 
          setShowSpinner(false);
        }, 1500);
        setShowSpinner(true)
        */

      } catch (error) {
        console.error('Error fetching data:', error);
        setIsError(true);
        setErrorMsg(error)
      }
    };
    fetchData();


    const intervalId = setInterval(fetchData, 60000);
    //setIntervel cleaning process
    return(()=>{
      clearInterval(intervalId);
  }) 

  }, [count,clientId,clientName, strategy,security, broker]);

  const buttonListener = useCallback( e => {
    gridRef.current.api.deselectAll();
  }, []);

  const containerStyle = useMemo(() => ({  height: 'auto', width : '100%' , overflow: 'hidden' }), []);
  const gridStyle = useMemo(() => ({ height: '580px', width : '1060px' }), []);


    return (
        <>
      
    <TableRefresher cellClickedListener={cellClickedListener} showSpinner={isFetching}/>
    {formOpen && <div style={{
       backgroundColor: 'rgba(0,0,0,0.6)',
       position: 'fixed',
       height: '100%',
       width: '100%',
       top: 0,
       left: 0,
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
       zIndex: 1

      }}>
      <OrderForm 
        trade={updateData?.transactionType} 
        updateData={updateData} 
        setUpdateData={setUpdateData}
        hideForm = {() => setFormOpen(false)}
      />
      </div>}

    <div style={gridStyle} className="ag-theme-alpine">
        {
            isError ? 
            <Alert color="danger">
             {errorMsg}
            </Alert>
            : ''
        }
        {
           isFetching?
           <ShimmerTable row={4} col={4} />
           :
            <AgGridReact
            ref={gridRef} 
            rowData={rowData} 
            columnDefs={columnDefs} 
            defaultColDef={defaultColDef}           
            
            paginationPageSize={10} 
            animateRows={true} 
            autoSizeStrategy={autoSizeStrategy}
            //domLayout='autoHeight'
            />
        }
      </div> 
    </>
  );
};

export default OrderTable;

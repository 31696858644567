/* eslint-disable */
import React,{useState, useRef} from "react";
import { Link, useLocation } from "react-router-dom";
import { useGlobalState,setGlobalState} from "components/globalVariable";
import Cookies from "universal-cookie";
import Dropdown from 'react-bootstrap/Dropdown';
import MarginTemplate from "components/Margin/container/MarginTemplate.js";
//import '../Navbars/DemoNavbar.css'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from "reactstrap";

import routes from "../../routes.js";
import logo from '../../assets/img/favicon.png'
import style from './DemoNavbar2.css';
import { LogIn, User, UserCheckIcon, UserCircle, UserSquare } from "lucide-react";
import { CiUser } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { setUserList } from "reducers/FilterSlice.js";
import { useEffect } from "react";
import config from 'config.js'
import BetaContainer from "components/Beta/BetaContainer.jsx";
import fetchBetaValues from "components/Beta/getPortfoliobeta.js";

export const DemoNavbar3 = (props) => {
  const [selectedClients, setSelectedClients ] = useState([])
  const cookies = new Cookies();
  const oneYearFromNow = new Date();
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [userId,setUserId]=useState('')
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();
  const [clientId] = useGlobalState("clientId")
  const [clientName] = useGlobalState("clientName");
  const [accountIds] = useGlobalState("accountIds");
  const availableMargin = useSelector(state => state.marginTemplate.availableMargin)
  const elementBtn = useRef(null);

  

    
  
  
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };

  const getBrand = () => {
    let brandName = "Orders";
    routes.map((prop, key) => {
      
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });        
    return brandName;
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    //sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };

  

  const [selUsers,setSelUsers] = useState(null);
  const [trigger,setTrigger] = useState(false);
  //using two states to keep track of multiple client ids 
  //with useEffect
  React.useEffect(()=>{
    if(selectedClients?.clientId){
        setGlobalState('clientId',selectedClients.clientId);
        setGlobalState('clientName',selectedClients.clientName);
        setGlobalState('accountIds',[1,2]);
    }
   
    
    if(!selUsers){
      setSelUsers(selectedClients);
    }
    else if(!selUsers.includes(selectedClients)){
      setSelUsers((prev)=>[...prev,selectedClients]);
    }
    else{
      let filteredUsers = selUsers.filter((user)=>user?.clientId!==selectedClients?.clientId)
      setSelUsers(filteredUsers)
    }
    
  },[selectedClients,trigger])
 

  React.useEffect(()=>{

    cookies.set('clientId', clientId);
    cookies.set('clientName', clientName);
    cookies.set('accountIds', accountIds);
    
    //console.log(cookies.cookies);

    fetchBetaValues(clientId,accountIds,dispatch)

  },[clientId,selectedClients,clientName])

  
  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      //sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  const handleMenuClick = () => {
    elementBtn.current?.classList.toggle('open');
    setIsOpen(prev => !prev);
  };
  const userList = useSelector(state => state.filter.userList)
  const dispatch = useDispatch()

  const getUserlist = () => {
    fetch(`${config.REACT_APP_DEV_URL}getClientInfo`, {
        method : 'GET'
      })
      .then(response => {
        if (!response.ok) {
            throw new Error("It is error");
          }
          return response.json();
      })
      .then(data => {
        dispatch(setUserList( data.map((item, index) => ({
        ...item,
        accountIds: [1]
    }))));})
      .catch(error => console.error("Error",error))
}
  React.useEffect(()=>{getUserlist()} , [])
  return (
    
<nav className="w-full shadow-md p-1">
    <div className="flex items-center justify-between px-2 ">
        <div className="flex items-center content-center ml-3 font-semibold">
            <p  className="text-black/80 font-semibold text-lg">{getBrand() }</p>
        </div>
        <div className="items-center space-x-6 lg:flex w-600 hidden">
              <InputGroup className="border mt-2 ">
                    <Input placeholder="Search..." />
                    <InputGroupAddon addonType="append">
                        <InputGroupText>
                        <i className="nc-icon nc-zoom-split" />
                        </InputGroupText>
                    </InputGroupAddon>
              </InputGroup>
              {(clientId== "undefined" || clientId == undefined)? null  :(<BetaContainer/>)}
             {(clientId== "undefined" || clientId == undefined)? null  :(<MarginTemplate/>)}
                
              <div className="dropdown relative group cursor-pointer" onClick={()=>{
                setDropdownOpen(true)
              }}>
                  <div className="flex items-center space-x-2">
                    <CiUser size={24} />
                    <button className="border text-black font-semibold text-sm py-2 px-4 flex rounded items-center justify-between min-w-32 w-auto z-20"> 
                      <span className="mr-1 font-semibold">{(clientName==='undefined'|| !clientName)?'Select Profile':clientName.toUpperCase()}</span>
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                    </button>
                  </div>
                  <ul className={`hidden group-hover:block absolute right-0 rounded  text-gray-800 py-2 px-2 text-sm font-semibold w-44 z-[1000]`}>
                    {
                      userList.length?
                        userList.map((item,index)=>{
                            return(
                                <li key={index}
                                    onClick={()=>{
                                        setSelectedClients(item);
                                        //setTrigger((prev)=>!prev);
                                    }}
                                >
                                    <p className={
                                        "bg-gray-300  py-2 px-4 block border m-0"+
                                        `${index===0?' rounded-t ':' '}`+
                                        `${index===userList?.length-1?' rounded-b ':' '}`+
                                        `${item.clientName===clientName?'bg-gray-400 ':' hover:bg-gray-400'}`
                                    } 
                                    >
                                        {" "}{item.clientName}
                                    </p>
                                </li>
                            )
                        })
                      :
                      <li><p className="bg-gray-300  py-2 px-4 block border m-0 rounded ">Loading...</p></li>
                    }
                    </ul>
              </div>  
        </div>
    </div>
</nav>
    
  );
}


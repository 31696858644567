/*eslint-disable*/

import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Validity from "../Validity/Validity";
import {Autocomplete, TextField, Paper, List, ListItem, ListItemText,Popper } from "@mui/material";
import Button from '@mui/material/Button';
import { MdCancel } from "react-icons/md";
import { NumberCellEditor } from "ag-grid-community";
import { useZenSecurities1 } from "components/getSecurities";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {saveSecurity,saveExchange} from "reducers/FilterSlice";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useRealtimeZenSecurities } from "components/getSecurities";
import { setTradingSymbol, setShowOrderForm } from "components/OptionChain/OptionChainSlice";
import { saveStrategy } from "reducers/FilterSlice";
import { setOrderStrategyId } from "reducers/FilterSlice";
import PositionTableOptionSlice from "components/PositionsGrid/container/PositionTableOptionSlice";
import { saveOrderDetails } from "components/PositionsGrid/container/PositionActionSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



export default function OrderFormTemplate({
      formData,
      setUpdateData,
      setFormData,
      companiesList,
      setFormOpen,
      trade,
      handleSubmit,
      setError,
      onSubmit,
      register,
      errors,
      setValue,
      getValues,
      watch,
      OrderType,
      orderValue,
      handleChange,
      Prices,
      broker,
      zen,
      hideForm,
    }) {
      const [active,setActive]=useState("")
  const orderStrategyId = useSelector(state => state.filter.orderStrategyId)

      const filterOptions = (options, { inputValue }) => {
        const results = options.filter(option =>
            option.title.startsWith(inputValue.toUpperCase())
        ).slice(0,2000)
        return results
      }
      const [autocompleteValue,setAutoCompleteValue] = useState("");
      
      //const zenSecuritiesData1 = useZenSecurities1(today);
      const zenSecuritiesData1 = useRealtimeZenSecurities();
      const [securities,setSecurities] = useState([]);
      const filter = useSelector(state => state.filter);
      const [lotSize,setLotSize] = useState(1);
      const [lots,setLots] = useState(1);
      const dispatch = useDispatch();
      const [type, setType] = useState("EQ");
      const autoSquareTradingSymbol = useSelector(state => state.positionActions.tradingSymbol);
      const postionTableRowData = useSelector(state => state.positionActions.orderDetails)
      const optionChainTradingSymbol = useSelector(state => state.optionChainTemplate.tradingSymbol)

    const handleTypeChange = (event, newValue) => {
        setType(newValue);
        setValue("tradingSymbol",null);
        setValue("quantity","")
    };

    useEffect(()=>{
      if(postionTableRowData){
        if(postionTableRowData?.isNFO){
          setType("OPT")
        }
        else{
          setType("EQ")
        }
      }
    },[postionTableRowData])
      
      
    function sortOptions(options) {
          return options.sort((a, b) => {
            // Extract the option type (PE or CE) from the title
              const aType = a.title.slice(-2);
              const bType = b.title.slice(-2);
            
            // Extract the trading symbol without the option type
              const aSymbol = a.title.slice(0, -2);
              const bSymbol = b.title.slice(0, -2);
            
            // If the symbols are different, sort by symbol
              if (aSymbol < bSymbol) return -1;
              if (aSymbol > bSymbol) return 1;
            
            // If the symbols are the same, prioritize CE over PE
              if (aType === 'CE' && bType === 'PE') return -1;
              if (aType === 'PE' && bType === 'CE') return 1;
            
            // If both are same (both PE or both CE), maintain original order
              return 0;
          });
    }
         
    function filterData(){
          let list = [];
          zenSecuritiesData1.then((data)=>{
              for (const exchange in data){
                if(exchange === "ZEN_EXCHANGE"){
                 if(type!=="OPT"){
                  data[exchange][type]?.map((item,v)=>{
                    list.push({
                      title:item.tradingSymbol,
                      lotSize:item.lotSize,
                      exchange:exchange,
                      index:v});
                   }) 
                 }
                 else{
                  data[exchange]["PE"]?.map((item,v)=>{
                    list.push({
                      title:item.tradingSymbol,
                      lotSize:item.lotSize,
                      exchange:exchange,
                      index:v});
                   })
                  data[exchange]["CE"]?.map((item,v)=>{
                    list.push({
                      title:item.tradingSymbol,
                      lotSize:item.lotSize,
                      exchange:exchange,
                      index:v});
                   })
                  list = sortOptions(list);
                 }
              }
            }
          }).then(()=>{
            setSecurities(list);
          })
         
         
    }
    //Filtering data
    const [isSecuritySelect,setIsSecuritySelect] = useState(false);
    //If security is null then user should not able to enter lots or quantity
    //For tracking that 
    useEffect(()=>{
        filterData();
        setIsSecuritySelect(false);
        setLots(1)
    },[type])

    useEffect(()=>{
      if(autocompleteValue===""){
        setIsSecuritySelect(false)
      }else{
        setIsSecuritySelect(true)
      }
    },[autocompleteValue])

    useEffect(()=>{
      const handleEsc = (event)=>{
        if(event.key==='Escape'){
          if (formData !== null && formData !== undefined) {
            setUpdateData(null);
          } else {
            setFormOpen((formOpen) => !formOpen);
          }
          hideForm(formData, setUpdateData)
          dispatch(setTradingSymbol(""));
          dispatch(setShowOrderForm(false));
          dispatch(saveOrderDetails(""))
          
        }
      }
      window.addEventListener('keydown', handleEsc);

      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    },[])

    const [isQtyFocus,setQtyFocus] = useState(false);
    const [isLotsFocus,setLotsFocus] = useState(false);

    useEffect(()=>{
      if(isLotsFocus)setValue("quantity",lotSize*lots)
    },[lots,lotSize])

    const qty = watch("quantity");
    useEffect(() => {
      if (qty !== undefined && isQtyFocus) {
        const newlot = Math.floor(qty/lotSize)
        setLots(newlot)
      }
    }, [qty, lotSize]);

  const [open, setOpen] = useState(false);
  const [pendingData, setPendingData] = useState(null);

  const handleDialogClose = (confirmed) => {
    setOpen(false);
    if (confirmed && pendingData) {
      onSubmit(pendingData);
    }else{
      setError("quantity", { type: "manual", message:"Enter value less than 1000" }, { shouldFocus: true })
    }
  };

  const handleFormSubmit = (data) => {
    if(data.quantity%lotSize!==0){
      setError("quantity", { type: "manual", message:"Invalid Quantity!" }, { shouldFocus: true })
      return;
    }
    if (data.quantity > 1000) {
      setPendingData(data);
      setOpen(true);
    } else {
      onSubmit(data);
    }
  };

  useEffect(()=>{
    let lotsData
    if(autoSquareTradingSymbol!=="" ){
      zenSecuritiesData1.then((data)=>{
        for (const exchange in data){
          if(exchange === filter.exchange){
            if(autoSquareTradingSymbol.includes("CE")){
              lotsData = data[exchange]["CE"]?.filter((item)=>item.tradingSymbol===autoSquareTradingSymbol)[0]
            }
            else if(autoSquareTradingSymbol.includes("PE")){
              lotsData = data[exchange]["PE"]?.filter((item)=>item.tradingSymbol===autoSquareTradingSymbol)[0]
            }
            else if(autoSquareTradingSymbol.includes("FUT")){
              lotsData = data[exchange]["FUT"]?.filter((item)=>item.tradingSymbol===autoSquareTradingSymbol)[0]
            }else{
              lotsData = data[exchange]["EQ"]?.filter((item)=>item.tradingSymbol===autoSquareTradingSymbol)[0]
            }
          }
        }
      }).then(()=>{
        setLotSize(lotsData?.lotSize)
      }).then(()=>{
        setValue("quantity",lotsData?.lotSize*lots)
      })
    }
  },[autoSquareTradingSymbol])

  useEffect(()=>{
    let lotsData
    if(optionChainTradingSymbol!=="" ){
      zenSecuritiesData1.then((data)=>{
        for (const exchange in data){
          if(exchange === filter.exchange){
            if(optionChainTradingSymbol.includes("CE")){
              lotsData = data[exchange]["CE"]?.filter((item)=>item.tradingSymbol===optionChainTradingSymbol)[0]
            }
            else if(optionChainTradingSymbol.includes("PE")){
              lotsData = data[exchange]["PE"]?.filter((item)=>item.tradingSymbol===optionChainTradingSymbol)[0]
            }
            
          }
        }
      }).then(()=>{
        setLotSize(lotsData?.lotSize)
      }).then(()=>{
        setValue("quantity",lotsData?.lotSize*lots)
      })
    }
  },[optionChainTradingSymbol])

  const [exchange, setExchange] = useState("NSE"); // Initialize with default value
  
  useEffect(() => {
    if (broker === "ZERODHA") {
      if(type==="EQ"){
        setExchange("NSE");
        dispatch(saveExchange(("NSE")));
      }else{
        setExchange("NFO");  // Set exchange to NSE for Zerodha
        dispatch(saveExchange(("NFO")));
      }
    } else {
      setExchange("ZEN_EXCHANGE");  // Set exchange to ZEN_EXCHANGE for Zen Exchange
      dispatch(saveExchange(("ZEN_EXCHANGE")));
    }
  }, [broker,type]);  // Re-run whenever broker changes

  return (

    <div className="order-form bg-blue-50 border-[1px] border-black/60">
          <div className={`p-3 mt-0 text-white flex flex-wrap font-semibold cursor-move ${trade === "BUY" ? "bg-blue-600" : "bg-slate-600"}`}>
            <span className="flex-shrink-0 w-[460px]">{trade}  {optionChainTradingSymbol? optionChainTradingSymbol: autoSquareTradingSymbol?autoSquareTradingSymbol:zen ? zen : "" }</span>
            <button
              onClick={() => {
                hideForm(formData, setUpdateData)
                // if (formData !== null && formData !== undefined) {
                //   setUpdateData(null);
                // } else {
                //   setFormOpen((formOpen) => !formOpen);
                // }
                dispatch(setTradingSymbol(""))
              }}
              className={trade === "BUY" ? "flex-wrap bg-blue-600" : "flex-wrap bg-slate-600"}
            >
              <MdCancel className="text-white hover:scale-150" />
            </button>
          </div>

        <Dialog
              className="z-[100000]"
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => handleDialogClose(false)}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Are you sure to proceed?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                Quantity is greater than 1000
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button onClick={() => handleDialogClose(true)}>Okay</Button>
              </DialogActions>
        </Dialog>
  <form className="form cursor-auto" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="form-div">
          {zen || autoSquareTradingSymbol || optionChainTradingSymbol ?
          null:<Box>
            <Tabs
              onChange={handleTypeChange}
              value={type}
              aria-label="Tabs where each tab needs to be selected manually"
            >
              <Tab label="EQ" value={"EQ"}/>
              <Tab label="OPT" value={"OPT"}/>
              <Tab label="FUT" value={"FUT"}/>
            </Tabs>
          </Box>}
          <div className="tab">
            <div>
              <label className="form-label" style={{color:"black"}}>Security</label>
                 <Autocomplete
                  disablePortal
                  key={type}
                  noOptionsText={securities.length?"No results found":"Loading..."}
                  className="auto-complete"
                  style={{ width: 250 }}
                  id="combo-box-demo"
                  inputValue={autocompleteValue}
                  onInputChange={(event, newInputValue,option) => {
                    setAutoCompleteValue(newInputValue);
                  }}
                  onChange={(e,value)=>{
                    if(value?.lotSize){
                      setLotSize(value.lotSize);
                      setValue("quantity",lots*value?.lotSize)
                    }else{
                      setLotSize('NA')
                    }
                  }}
                  disabled={formData !== undefined}
                  defaultValue={optionChainTradingSymbol?optionChainTradingSymbol: autoSquareTradingSymbol?autoSquareTradingSymbol:zen ? zen : formData !== undefined  ? formData.tradingSymbol : null}
                  options={securities}
                  filterOptions={filterOptions}
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.title ||zen || autoSquareTradingSymbol || optionChainTradingSymbol}
                  sx={{
                      width: 230,
                      "& .MuiInputBase-root": {
                          fontSize: ".9rem",
                          height: "40px",
                          fontWeight: "500",
                          paddingTop:"0px",
                          bgcolor: "white"
                      },
                    "& .MuiAutocomplete-option.Mui-focused": {
                      bgcolor: "rgba(0,0,0,0.3)",
                      
                    }
                  }}
                  renderInput={(params) => (
                      <TextField {...params} required {...register("tradingSymbol")} />
                  )}
                  renderOption={(props, item) =>{
                    return(
                      <span {...props} key={item.title+item.exchange+item.index}>
                        {item.title}
                      </span>
                    )
                  }
                }
              />
              <span className="form-error">{errors.tradingSymbol?.message}</span>
            </div>

            
            <div>
              <label className="form-label" style={{color:"black"}}>Order</label>
              <Autocomplete
                className="auto-complete"
                disablePortal
                id="combo-box-demo"
                options={OrderType}
                onChange={(e, selectedOption) =>handleChange(selectedOption)}
                disabled={formData !== undefined}
                PaperComponent={({ children }) => (
                  <Paper sx={{
                    "& .MuiInputBase-root":{padding:"0px 0px 0px 5px"},
                    "& .MuiAutocomplete-listbox": {
                      display: 'flex',
                      flexDirection: 'column',
                      "& .MuiAutocomplete-option": {
                        width: '230px',
                        fontSize: '0.7rem',
                      },
                    },
                    "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
                      bgcolor: "rgba(0,0,0,0.3)",
                    },
                    
                  }}>
                    {children}
                  </Paper>
                )}
                sx={{
                  width: 230,
                  "& .MuiInputBase-root": {
                    fontSize: ".8rem",
                    height: "40px",
                    fontWeight: "500",
                    bgcolor: "white"
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: "2rem" }}
                    {...params}
                    required
                    {...register("orderType")}
                  />
                )}
              />
            </div>
          </div>

          <div className="tab">

          {Prices.map((el) => (
            <Form.Group className="mb-1"  key={el}>
              <Form.Label className="form-label" style={{color:"black"}}>{(el[0].toUpperCase()+el.slice(1,)).replace(/([a-z])([A-Z])/g, '$1 $2')}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Price"
                className="input-size"
                value={orderValue.includes(el) ? '' : undefined}
                disabled={orderValue.includes(el)}
                {...register(el, {
                  required:orderValue.includes(el)?false:"Enter the price",
                  validate: {
                    checkValue: (val) =>
                      !(val < 0 || val > 20000) || "Enter price (0-20,000)",
                  },
                  pattern: {
                    value: /^-?\d+(\.\d+)?$/,
                    message: "Price should not include characters",
                  },
                })}
              />
              {!orderValue.includes(el) && errors[el] && (
                <span className="form-error">{errors[el].message}</span>
              )}
            </Form.Group>
          ))}
          </div>

          <div className="tab">
          <Form.Group className="mb-1">
              <Form.Label style={{color:"black"}}>Lots :</Form.Label>
              
              <Form.Control
                disabled={type!=="EQ"?false:true}
                type="number"
                placeholder="Lots Size"
                value={lots}
                onChange={(e)=>setLots(e.target.value)}
                defaultValue={null}
                className="input-size"
                onFocus={()=>setLotsFocus(true)}
                onBlur={()=>setLotsFocus(false)}
              />
              
              </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label style={{color:"black"}}>Quantity : </Form.Label>
              <Form.Control
                disabled={type==="EQ"?false:true}
                type="number"
                placeholder="Quantity"
                defaultValue={lotSize}
                className="input-size"
                {...register("quantity", {
                  valueAsNumber: true,
                  required: "Enter the quantity",
                  validate: {
                    checkValue: (val) => {
                      return (
                        !(val < 1 || val > 10000) || "Enter Quantity (1 - 10000)"
                      );
                    },
                  },
                  pattern: {
                    value: /^-?\d+(\.\d+)?$/,
                    message: "Quantity not include characters",
                  },
                })}
                onFocus={()=>setQtyFocus(true)}
                onBlur={()=>setQtyFocus(false)}
              />
              <span className="form-error"> {errors.quantity?.message}</span>
            </Form.Group>

          </div>

          <div className="tab">
              <Form.Group className="mb-1">
                <Form.Label style={{color:"black"}}>Product</Form.Label>
                <Form.Select className="input-size border-[1px] border-slate-400" disabled={formData !== undefined}  {...register("product")}>
                  <option value="CNC">CNC</option>
                  <option value="NRML">NRML</option>
                  <option value="MIS">MIS</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label style={{color:"black"}}>Broker</Form.Label>
                <Form.Select className="input-size border-[1px] border-slate-400" disabled={formData !== undefined}  {...register("broker")} >
                  <option value="ZEN_BROKER">ZEN_BROKER</option>
                  <option value="ZERODHA">ZERODHA</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label style={{ marginBottom: '2px', color: "black" }}>Exchange</Form.Label>
                <Form.Select
                  className="input-size border-[1px] border-slate-400"
                  disabled={formData !== undefined}
                  {...register("exchange")}
                  value={exchange} // Controlled component
                  onChange={(e) => {
                    setExchange(e.target.value); 
                    
                  }} // Handle change
                >
                  {
                    broker === "ZERODHA"? 
                      type==="EQ" ? (
                        <option value="NSE">NSE</option>
                      )
                      :
                      (
                        <option value="NFO">NFO</option>
                      )
                    : (
                      <option value="ZEN_EXCHANGE">ZEN_EXCHANGE</option>
                    )
                  }
                </Form.Select>
            </Form.Group>

              
          </div>
          
          <div className="tab" style={{width: "100px"}}>
              <Form.Group className="mb-1">
                <Form.Label style={{color:"black", marginBottom: "2px"}}>Strategy</Form.Label>
                <Form.Select className="input-size border-[1px] border-slate-400" disabled={formData !== undefined}  {...register("strategyId")} >
                  defaultValue={"One"}
                  <option value={1}>One</option>
                  <option value={11000}>2PM_STRATEGY</option>
                  <option value={11001}>2PM_STRATEGY_TEST1</option>
                  <option value={11002}>2PM_STRATEGY_TEST2</option>
                  onChange=
                  {
                    e => 
                    {
                      dispatch(setOrderStrategyId((e.target.value)));
                    }
                  }
                </Form.Select>
              </Form.Group>
              
            </div>
            <div className="tab" style={{width: "100px"}}>
              <Form.Group className="mb-1">
                <Form.Label style={{color:"black", marginBottom: "2px"}}>Account Id</Form.Label>
                <Form.Select className="input-size border-[1px] border-slate-400" disabled={formData !== undefined}  {...register("accountId")} >
                  defaultValue={"1"}
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                </Form.Select>
              </Form.Group>
              
            </div>
        </div>


        <Validity
          formData={formData}
          setFormData={setFormData}
          register={register}
        />

       <button className={trade == "BUY" ? "submit-btn blue" : "submit-btn black"}>{!zen ? trade : "Place Order"}
        </button> 
      </form>
    </div>
  );
}
